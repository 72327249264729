<i18n src="@/locales/article810.json" />

<template>
  <div class="fixed-background">
    <header class="columns is-centered">
      <div class="column is-9">
        <h1 class="has-background-primary-light-opacity mt-2">
          {{ $t('h1_title') }}
        </h1>
        <img
          src="@/assets/img/articles/article810/danger-devitalisation-dent.jpg"
          alt=""
        >
      </div>
    </header>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message2')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('article1_title')">
      <img
        src="@/assets/img/articles/article810/devitalisation-dent-carie.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article1_p1')
          }}
        </p>
        <div v-html="$t('article1_p2')" />
        <div v-html="$t('article1_p3')" />
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article2_title')">
      <img
        src="@/assets/img/articles/article810/devitalisation-dent-carie.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article2_p1')
          }}
        </p>
        <p>
          {{
            $t('article2_p2')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article21_title')">
      <img
        src="@/assets/img/articles/article810/risque-devitalisation-dent.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article2_p3')
          }}
        </p>
        <p>
          {{
            $t('article2_p4')
          }}
        </p>
        <p>
          {{
            $t('article2_p5')
          }}
        </p>
        <p>
          {{
            $t('article2_p6')
          }}
        </p>
        <p>
          {{
            $t('article2_p7')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article3_title')">
      <img
        src="@/assets/img/articles/article810/toxine-bacterie-dent.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article3_p1')
          }}
        </p>
        <div v-html="$t('article3_p2')" />
        <p>
          {{
            $t('article3_p3')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article4_title')">
      <div class="content">
        <p>
          {{
            $t('article4_p1')
          }}
        </p>
        <p>
          {{
            $t('article4_p2')
          }}
        </p>
        <p>
          {{
            $t('article4_p3')
          }}
        </p>
        <p>
          {{
            $t('article4_p4')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article5_title')">
      <div class="content">
        <p>
          {{
            $t('article5_p1')
          }}
        </p>
        <div v-html="$t('article5_p2')" />
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article6_title')">
      <div class="content">
        <div v-html="$t('article6_p1')" />
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article7_title')">
      <div class="content">
        <div v-html="$t('article7_p1')" />
      </div>
    </ImanTestimonialArticle>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Article810",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/76l6Uj9uB9c'
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/miniature_Dent_devitalisee.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
h1 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-size: 1.7rem;
  font-weight: lighter;
  line-height: $title-line-height;
  padding: 0.5rem;
}
</style>
